.container.product-listing {
  
    .navbar-expand-lg .navbar-collapse {
        @media (min-width: 992px) {
        display: block !important;
        }
    }
     // Toggle filter
    button.navbar-toggler.filterbar-toggle {
        width: 180px;
        left: 0;
        border-radius: 1px;
        border: 1px solid #ddd !important;
        margin: auto 0;
        margin-bottom: 0 !important;
        position: relative;
        font-size: 15px;
        right: 40px;
        top: -3rem;
        bottom: 4rem;
    }

.filter-block {
    box-shadow: none !important;
    border: 1px solid #eee;
    border-radius: 1px;
    .search-btn {
        border: none;
    }
    .link-button {
        border: solid 1px #ccc;
        padding: 4px 10px;
        width: 100%;
        color: #666;
        margin-top: 1em;
        margin-bottom: 0 !important;
        &:hover{
            background: #eee;
        }
    }

    .filter-list-container {
        border-top: none;
        padding: 0 !important;
        .filter-items-list {
            overflow: visible !important;
            max-height: fit-content !important;
            padding: 0 !important;
        }

        label.form-check-label {
            position: relative;
            cursor: pointer;
            transition: color .2s ease-out;
            display: flex;
            margin: 0;
            align-items: center;
            font-size: 14px;
        }
    }
}

// Sort button

.d-flex.justify-content-end.sort-options.py-4 {
    padding: 0px 0px 8px 0px !important;
    left: -12px;
    margin: 0;
    margin: 0;
    ul.dropdown-menu.dropdown-menu-end.show {
        border-radius: 1px;
        transform: translate3d(0px, 39.5px, 0px) !important;
        min-width: 11.25em;
    }
}
}