// Product listing image constrain 

img.productImage.productCardImage {
    max-height: 12rem;
    width: auto;
}

// Footer

.footer-ribbon.footer-ribbon-three {
    border-top: 1px solid #fff;
}

// Header

a.nav-link::before {
    padding-right: 5px;
}

// Logo

img.img-fluid.navbar-brandLogo {
    min-width: 210px !important;
}

// Core overwrite

.footer {
    .container.d-flex.justify-s-b.pt-5.px-2.flex-wrap {
        padding-top: 0 !important;
    }
}
// Hide toggle button

.banner-switch-div {
    display: none !important;
}

// Style overwrite - breadcrumb padding 

.container.p-4 {
    .row.d-flex.flex-wrap {
        .bg-light.px-4.py-3.pt-4 {
            padding-top: 15px !important;
            .d-block.mb-3.mb-lg-0.pt-lg-2 {
                padding-top: 0 !important;
            }
        }
    }
}

body {
    background-color: #ffffff;
}

img.productImage.image-zoom {
    max-height: 30rem;
    width: auto;
}

// Hiding Search icon for mobile
@media (max-width: 991px) {
    i.bi.bi-person-circle.account-bubble-icon.fs-4 {
        display: none;
    }
}